<template>
  <div class="con">
    <div class="con_Header">
      <div style="display: flex" class="con_Header_left">
        <i
          class="iconfont iconjiantou-zuo left"
          v-if="isShowGoback"
          @click="goBack"
        ></i>
        <svg class="icon operationImg" aria-hidden="true">
          <use href="#icon-gantticonImg"></use>
        </svg>

        <div style="margin-top: 20px">
          <!-- <div style="margin-top: 10px">{{ projectTitleText }}</div> -->
          <p style="margin-bottom: 10px" v-if="isShowGoback">
            {{ $route.query.proName }}
          </p>
          <el-popover
            v-if="!isShowGoback"
            placement="bottom"
            v-model="visible"
            width="200"
            trigger="click"
            @hide="hidePopver"
          >
            <DownList
              @handleItem="handleItem"
              :selectproject="selectproject"
              :loading="downLoading"
              :itemlist="downItemlist"
              :key="key"
              @searchDownList="searchDownList"
            />
            <div class="downIcon" slot="reference">
              <span>{{ selectname }}</span>
              <i class="el-icon-caret-bottom"></i>
            </div>
          </el-popover>
        </div>
      </div>
      <!-- 表头右侧功能按钮 -->
      <div class="con_Header_right">
        <!-- 固定的三个按钮 -->
        <div class="fixed_btn">
          <el-radio-group
            v-model="radio"
            @change="switchviews"
            style="padding-right: 10px"
          >
            <!-- 
            甘特图:$t("label.projectManagement.gantt")
            资源使用视图:$t("label.projectManagement.resource")
            任务看板:$t("label.projectManagement.kanban")
           -->
            <el-radio :label="1">{{
              $t("label.projectManagement.gantt")
            }}</el-radio>
            <el-radio :label="2">{{
              $t("label.projectManagement.Resource.usage.view")
            }}</el-radio>
            <el-radio :label="3">{{
              $t("label.projectManagement.kanban")
            }}</el-radio>
          </el-radio-group>
          <!-- <el-button type="primary" size="mini">资源管理</el-button>
          <el-button type="primary" size="mini">甘特图</el-button>
          <el-button type="primary" size="mini">任务看板</el-button> -->
          <!-- 新建里程碑: $t("label.projectManagement.Newmilestone")-->
          <el-button
            type="primary"
            size="mini"
            v-show="
              radio == 3 && currentSelectChecked == 'ffe20201207task11'
                ? isShowMilepostBtn
                : false
            "
            @click="newMilestoneButton"
            >{{ $t("label.projectManagement.Newmilestone") }}</el-button
          >
          <!-- 任务看板页面展示按钮 -->
          <!-- 请选择: $t("label.emailtocloudcc.select1")-->
          <el-select
            v-show="radio == 3"
            v-model="currentSelectChecked"
            :placeholder="$t('label.emailtocloudcc.select1')"
            @change="getKanBanData(currentSelectChecked)"
          >
            <el-option
              v-for="item in optionss"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              style="font-weight: bold"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div></div>
    </div>
    <!-- <Gantt
      v-if="isShowGantt"
      ref="child"
      class="left-container"
      :tasks="tasks"
      :newSpos="newSpos"
      v-loading="ganttLoading"
      @change-spos="changspos"
      @task-updated="logTaskUpdate"
      @link-updated="logLinkUpdate"
    ></Gantt> -->
    <CanttComponent
      v-if="isShowGantt && selectproject"
      ref="ganttChild"
      :key="selectproject"
      class="left-container"
      :proId="selectproject"
    ></CanttComponent>
    <!-- 看板任务 -->
    <div class="kanbanBox" v-if="isShowkanbanTaskPage">
      <projectTasks
        ref="changeImplement"
        :projectId="selectproject"
        @childfunc="childfunc($event)"
      ></projectTasks>
    </div>
    <resourceManage
      v-if="isShowResource"
      :projectId="selectproject"
      :selectname="selectname"
    />
    <el-dialog
      :visible.sync="dialogVisible"
      width="30%"
      :modal="false"
      :modal-append-to-body="false"
      :show-close="false"
    >
      <div slot="title" class="header-title" v-if="selectedTask">
        <div class="header-titletext">
          <span>{{ selectedTask.text }}</span>
        </div>
      </div>
      <div class="dialog-center" v-if="selectedTask">
        <div class="dialog-centerleft">
          <p>{{ selectedTask.user }}</p>
          <p>
            {{
              selectedTask.start_date.getFullYear() +
              "-" +
              (selectedTask.start_date.getMonth() + 1) +
              "-" +
              selectedTask.start_date.getDate()
            }}
            <!-- 至: $t("label.appointment.wizard.subtitle2-2") -->
            {{ $t("label.appointment.wizard.subtitle2-2") }}
            {{ selectedTask.Nextname.substring(0, 10)
            }}<span v-show="selectedTask.continue_date">
              <!-- 天:$t("label.chatter.day") -->
              （{{ selectedTask.continue_date
              }}{{ $t("label.chatter.day") }}）</span
            >
          </p>
          <p>{{ selectedTask.taskstatus }}</p>
          <el-button
            :type="0 == display ? 'primary' : 'default'"
            size="small"
            style="margin-top: 5px; margin-bottom: 15px"
            v-if="selectedTask.Isshow"
            @click="displayCheck(0, selectedTask.id)"
          >
            <!--前置任务:$t("label.projectManagement.Antecedents")  -->
            {{ $t("label.projectManagement.Antecedents") }}</el-button
          ><el-button
            :type="1 == display ? 'primary' : 'default'"
            size="small"
            v-if="selectedTask.Isshow"
            @click="displayCheck(1, selectedTask.id)"
          >
            <!-- 后置任务: $t("label.projectManagement.Posttask")-->
            {{ $t("label.projectManagement.Posttask") }}</el-button
          >
          <p
            style="color: #006dcc; margin-top: 15px; margin-bottom: 15px"
            v-if="pname && display == '0'"
          >
            {{ pname }}
          </p>
          <p
            style="color: #006dcc; margin-top: 15px; margin-bottom: 15px"
            v-if="tname && display == '1'"
          >
            {{ tname }}
          </p>
        </div>
        <div class="dialog-centerright">
          <el-progress
            type="circle"
            :percentage="parseInt(selectedTask.progress * 10) * 10"
          ></el-progress>
        </div>
      </div>
      <div class="add-task" v-if="selectedTask && selectedTask.Isshow">
        <p
          @click="addtask"
          v-if="!addtaskstate && display == 0 && selectedTask.Isshow && !pname"
          style="
            color: #006dcc;
            margin-bottom: 15px;
            border-top: 1px solid #d8d8d8;
            cursor: pointer;
          "
        >
          <!-- 添加前置任务: $t("label.projectManagement.Addpredecessors")-->
          {{ $t("label.projectManagement.Addpredecessors") }}
        </p>
        <p
          @click="addtask"
          v-if="!addtaskstate && display == 1 && selectedTask.Isshow && !tname"
          style="
            color: #006dcc;
            margin-bottom: 15px;
            border-top: 1px solid #d8d8d8;
            cursor: pointer;
          "
        >
          <!-- 添加后置任务: $t("label.projectManagement.Addposttask")-->
          {{ $t("label.projectManagement.Addposttask") }}
        </p>

        <div class="goto-task" v-if="addtaskstate">
          <h3 style="margin-top: 15px; margin-bottom: 10px">
            <!-- 转到里程碑: $t("label.projectManagement.Gotomilestone")-->
            {{ $t("label.projectManagement.Gotomilestone") }}
          </h3>
          <div>
            <!-- 请选择：$t("label.emailtocloudcc.select1") -->
            <el-select
              v-model="milepostid"
              :placeholder="$t('label.emailtocloudcc.select1')"
              style="width: 400px; margin-top: 5px; margin-bottom: 5px"
              @focus="getProjectIdMilestone"
              @change="getMilestoneIdSubTask"
            >
              <el-option
                v-for="item in milepost"
                :key="item.value"
                :label="item.text"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div>
            <!-- 请选择：$t("label.emailtocloudcc.select1") -->
            <el-select
              v-model="Subtasksid"
              :placeholder="$t('label.emailtocloudcc.select1')"
              style="width: 400px; margin-top: 5px; margin-bottom: 50px"
            >
              <el-option
                v-for="item in Subtasks"
                :key="item.value"
                :label="item.text"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- 取 消: $t("label.chatter.cancel")-->
        <el-button @click="cancel">{{ $t("label.chatter.cancel") }}</el-button>
        <!-- 确 定: $t("label.chatter.ok")-->
        <el-button type="primary" @click="sure">{{
          $t("label.chatter.ok")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 新增/修改记录 -->
    <create-edit-obj
      ref="createEditObj"
      :prefix="prefix"
      :objectApi="objectApi"
      :object-name="tabName"
      :realObjId="'projectworklist'"
      :relativeRecordId="relativeRecordId"
      :relationFieldId="relationFieldId"
      :id="id"
      :type="type"
      @save="save"
      @update="toUpdate"
      @reloadgatee="reload"
    >
    </create-edit-obj>
    <!-- 编辑$t("label.emailtocloudcc.edit") -->
    <el-dialog
      :title="$t('label.emailtocloudcc.edit')"
      :visible.sync="editstatus"
      width="30%"
    >
      <!-- 请选择：$t("label.emailtocloudcc.select1") -->
      <el-select
        v-model="status"
        :placeholder="$t('label.emailtocloudcc.select1')"
        style="margin-left: 100px"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <!-- 取 消: $t("label.chatter.cancel")-->
        <el-button @click="statuscancel">{{
          $t("label.chatter.cancel")
        }}</el-button>
        <!-- 确 定: $t("label.chatter.ok")-->
        <el-button type="primary" @click="statussave">{{
          $t("label.chatter.ok")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 选择: $t("label_tabpage_choose")-->
    <el-dialog
      :title="$t('label_tabpage_choose')"
      :visible.sync="typevalueoption"
      width="30%"
      center
      ><span style="margin-left: 80px; margin-right: 5px">
        <!-- 选择新建类型: $t("label.projectManagement.select.new.type")-->
        {{ $t("label.projectManagement.select.new.type") }}</span
      >
      <!-- 请选择：$t("label.emailtocloudcc.select1") -->
      <el-select
        v-model="typevalue"
        :placeholder="$t('label.emailtocloudcc.select1')"
      >
        <el-option
          v-for="item in optionstype"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <!-- 取 消: $t("label.chatter.cancel")-->
        <el-button @click="typechangecancel">{{
          $t("label.chatter.cancel")
        }}</el-button>
        <!-- 确 定: $t("label.chatter.ok")-->
        <el-button type="primary" @click="typechangesave">{{
          $t("label.chatter.ok")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/**
 * 甘特图
 */
import CanttComponent from "@/components/cloudcc-gantt/views/index.vue";
import * as projectApi from "./api";
import * as gateeApi from "./api.js";
import DownList from "./downList";
import projectTasks from "../projectTasks"; //看板任务
import createEditObj from "@/views/commonObjects/components/create-edit-obj.vue";
import resourceManage from "@/views/gantt/resourceManage/index.vue";
export default {
  name: "app",
  components: {
    CanttComponent,
    DownList,
    createEditObj,
    projectTasks,
    resourceManage,
  },
  data() {
    return {
      // back:'', // 路由是否是详情页返回
      changList: [], // 拖动修改任务时，存储需要修改的任务数据参数
      projectTitleText: this.$i18n.t("label.projectManagement.gantt"), // 甘特图:label.projectManagement.gantt
      options: [],
      status: "",
      editstatus: false,
      typevalueoption: false,
      newSpos: { x: 0, y: 0 }, // 滚动条新坐标
      // 查找成员弹框
      showSearchTable: false,
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      fieldId: "ffe2020Milestone06",
      relevantObjId: "cloudccprojectmember",
      relevantPrefix: "p11",
      tabName: "",
      isShowGantt: true, //是否显示甘特图页面
      isShowkanbanTaskPage: false, //是否显示看板任务页面
      show: true,
      //  viewId: this.$route.query.viewId,
      selectproject: "",
      Ganttproject: [],
      milepost: [], //获取里程碑
      milepostid: "", //选中里程碑id
      Subtasks: [], //里程碑下的所有子任务
      Subtasksid: "", //设置前/后置子任务id
      tasks: {
        data: [],
        links: [],
      }, //设置甘特图中任务值
      selectedTask: null, //选中任务信息
      messages: [],
      dialogVisible: false,
      addtaskstate: false, //是否显示转到里程碑
      display: "0",
      parentId: "", //被点击的子任务id
      pname: "",
      tname: "",
      predecessor_task_type: null, //前置任务type值
      the_rear_task_type: null, //后置任务type值
      isShowGoback: false, //控制返回按钮的显示隐藏
      loading: null,
      selectname: "",
      downLoading: false,
      downItemlist: [
        {
          name: this.$i18n.t("label.projectManagement.recent.projects"),
          id: "currentPro",
        },
      ], // 最近的项目：label.projectManagement.recent.projects
      key: "",
      visible: false,
      fixedtext: [],
      projectId: "", //看板视图需要的项目id
      currentSelectChecked: "ffe20201207task11",
      // 百分比：this.$i18n.t("label.percent")
      // 优先级this.$i18n.t("label.chatter.priority")
      // 里程碑this.$i18n.t("component.milestones.label.title")
      optionss: [
        {
          value: "ffe20201207task09",
          label: this.$i18n.t("label.percent"),
        },
        {
          value: "ffe20201207task10",
          label: this.$i18n.t("label.chatter.priority"),
        },
        {
          value: "ffe20201207task11",
          label: this.$i18n.t("component.milestones.label.title"),
        },
      ],
      // 新建里程碑按钮显示隐藏
      isShowMilepostBtn: true,
      // 看板下拉框
      kanbanDropDownBox: true,
      // 项目负责人id
      projectLeaderId: "",
      objectApi: "",
      relationFieldId: "",
      id: "", //项目id
      type: "active",
      statusid: "",
      statustype: "",
      radio: 1,
      typevalue: "1",
      changetypevalue: "",
      changetypeid: "",
      relativeRecordId: "",
      prefix: "",
      // 任务this.$i18n.t("label.chatter.task")
      // 服务预约this.$i18n.t("label.projectManagement.service.appointment")
      optionstype: [
        {
          value: "1",
          label: this.$i18n.t("label.chatter.task"),
        },
        {
          value: "2",
          label: this.$i18n.t("label.projectManagement.service.appointment"),
        },
      ],
      isShowResource: false, //是否显示资源使用
    };
  },
  filters: {
    toPercent(val) {
      if (!val) {
        return "0";
      }
      return Math.round(+val * 100);
    },
    niceDate(obj) {
      return `${obj.getFullYear()} / ${obj.getMonth() + 1} / ${obj.getDate()}`;
    },
  },
  created() {
    //gantt.clearAll() // 先清空，再添加，就不会有缓存
  },
  watch: {
    visible(val) {
      if (val) {
        this.key = 1;
      } else {
        this.key = 2;
        this.getTask();
        this.downLoading = false;
      }
    },
    // 默认进来是展示甘特图组件
    // $route: {
    //   handler: function (route) {
    //     if (route.fullPath === "/gantt/gantt") {
    //       this.isShowGantt = true;
    //       this.isShowkanbanTaskPage = false;
    //       this.projectTitleText = "甘特图";
    //       this.isShowResource=false;
    //     }
    //   },
    //   immediate: true,
    // },
  },
  beforeRouteLeave(to, from, next) {
    // 导航离开该组件的对应路由时调用
    // 存储当前展示的任务id和name到session中
    sessionStorage.setItem("selectname", this.selectname);
    sessionStorage.setItem("selectproject", this.selectproject);
    sessionStorage.setItem("radio", this.radio);
    // 可以访问组件实例 `this`
    // if (this.radio == "1") {
    //   this.$refs.child.reload();
    // }
    next();
  },
  methods: {
    reload() {
      // this.$refs.child.reload();
    },
    typechangecancel() {
      this.typevalueoption = false;
      this.typevalue = "1";
      // this.$refs.child.reload();
    },
    typechangesave() {
      this.add(this.changetypevalue, this.changetypeid, this.typevalue);
      this.typevalueoption = false;
      this.typevalue = "1";
      // this.$refs.child.reload();
    },
    addtype(type, id) {
      this.changetypevalue = type;
      this.changetypeid = id;
      this.typevalueoption = true;
    },
    //切换甘特图、资源管理、看板视图
    switchviews() {
      if (this.radio == "1") {
        this.getlist();
        this.isShowkanbanTaskPage = false;
        this.isShowGantt = true;
        this.isShowResource = false;
      } else if (this.radio == "3") {
        this.currentSelectChecked = "ffe20201207task11";
        localStorage.setItem("key", this.currentSelectChecked);
        this.isShowkanbanTaskPage = true;
        this.isShowGantt = false;
        this.isShowResource = false;
      } else if (this.radio == "2") {
        this.isShowResource = true;
        this.isShowkanbanTaskPage = false;
        this.isShowGantt = false;
      }
      // 保证刷新能够定位展示的组件
      sessionStorage.setItem("radio", this.radio);
    },
    //行内编辑状态、百分比
    Editstatus(status, id, type, arr) {
      this.options = arr;
      this.statusid = id;
      this.statustype = type;
      this.status = status;
      this.editstatus = true;
    },
    statuscancel() {
      this.editstatus = false;
      // this.$refs.child.reload();
    },
    //行内改变状态和百分比
    statussave() {
      this.editstatus = false;
      let obj = [
        {
          id: this.statusid,
          status: this.status,
        },
      ];
      let obj1 = [
        {
          id: this.statusid,
        },
      ];
      if (this.statustype === "CloudccProject") {
        obj1[0].complete_percentage = this.status + "%";
        if (this.status == "0") {
          obj1[0].status = "未开始"; //"未开始"
        } else if (this.status == "100") {
          obj1[0].status = "完成"; //完成
        } else {
          obj1[0].status = "进行中"; //"进行中"
        }
      } else if (this.statustype === "cloudccMilestone") {
        obj1[0].fulfill_percent = this.status + "%";
        if (this.status == "0") {
          obj1[0].status = "未开始"; //"未开始"
        } else if (this.status == "100") {
          obj1[0].status = "完成"; //完成
        } else {
          obj1[0].status = "进行中"; //"进行中"
        }
      } else if (
        this.statustype === "cloudccTask" ||
        this.statustype === "cloudccSubtask"
      ) {
        obj1[0].fulfill_percent = this.status + "%";
        if (this.status == "0") {
          obj1[0].task_status = "未开始"; //"未开始"
        } else if (this.status == "100") {
          obj1[0].task_status = "完成"; //完成
        } else {
          obj1[0].task_status = "进行中"; //"进行中"
        }
      } else {
        obj1[0].fulfill_percent = this.status + "%";
      }
      let params = {}
      if (this.statustype === "serviceappointment") {
        params = {
          data: JSON.stringify(obj),
          marketsea: "false",
          objectApi: "ServiceAppointment",
        };
      } else {
        params = {
          data: JSON.stringify(obj1),
          marketsea: "false",
          objectApi: this.statustype,
        };
      }
      if (this.statustype == "serviceappointment") {
        for (let i in this.tasks.data) {
          if (this.tasks.data[i].id == this.statusid) {
            this.tasks.data[i].status = this.status;
          }
        }
      } else {
        for (let i in this.tasks.data) {
          if (this.tasks.data[i].id == this.statusid) {
            this.tasks.data[i].Nextid = this.status;
          }
        }
      }
      gateeApi.save(params).then(() => {
        this.getlist();
      });
    },
    // 改变newspos
    changspos(spos) {
      this.newSpos = spos;
    },
    // 获取最近查看的5条任务
    getTask() {
      /**
       * 参数说明
       * 参数名	必选	类型	说明
       * obj	是	string	对象前缀
       * viewId	是	string	视图Id
       * pageNum	是	string	页数
       * pageSize	是	string	每页数量
       * sortField	是	string	排序字段apiname
       * sortDir	是	string	排序类型(desc[降序]/asc[升序])
       * searchKeyWord	是	string	搜索关键字
       * conditionValues	是	string	高级搜索条件
       * ischangenum	是	string	是否修改每页查询数目（修改的数目为视图默认每页数量）
       * recordnums	是	string	每页查询默认数目
       * tagIds	否	string	标签id，多个标签逗号分隔，按选中标签查询
       * isAllTag	否	string	是否同时满足所有选中标签（true/false）
       * campaignid	否	string	市场活动成员是否只查询现有成员
       * isallsearch	否	string	是否启用启用全局搜索，conditionValues不用空也不启用全局搜索
       * myself	否	string	全局搜索是否只查询所有人是自己的记录
       * scrollId	否	string	全文检索查询返回值，下一页查询回传
       * type	否	string	contact/lead特殊查询使用以不使用全文检索，参数暂时不用
       */
      let params = {
        obj: "p01",
        viewId: "0",
        pageNum: "1",
        pageSize: "5",
        sortField: "",
        sortDir: "",
        searchKeyWord: "",
        conditionValues: "",
        ischangenum: "false",
        recordnums: "5",
      };
      gateeApi.getViewListData(params).then((res) => {
        this.ganttLoading = false;
        this.Ganttproject = res.data.list.slice(0, 5);
        this.downItemlist = [];
        this.Ganttproject.forEach((item) => {
          let obj = {};
          obj.name = item.name;
          obj.id = item.id;
          this.downItemlist.push(obj);
        });
      });
    },
    // 新建
    add(type, id, value) {
      // this.prefix = "p05";
      this.tabName = "";
      this.id = "";
      this.relativeRecordId = id;
      // 任务label.chatter.task
      // 服务预约this.$i18n.t("label.projectManagement.service.appointment")
      // 里程碑this.$i18n.t("component.milestones.label.title")
      if (type == "cloudccMilestone") {
        if (value == "1" || value == undefined) {
          this.objectApi = "cloudccTask";
          this.relationFieldId = "ffe20201207task02";
          this.prefix = "p03";
          this.tabName = this.$i18n.t("label.chatter.task");
        } else {
          this.objectApi = "ServiceAppointment";
          this.relationFieldId = "ffe21sappointment083";
          this.prefix = "s41";
          this.tabName = this.$i18n.t(
            "label.projectManagement.service.appointment"
          );
        }
      } else {
        this.objectApi = "cloudccMilestone";
        this.relationFieldId = "ffe2020Milestone01";
        this.tabName = this.$i18n.t("component.milestones.label.title");
        this.prefix = "p02";
      }
      this.$nextTick(() => {
        this.$refs.createEditObj.add();
      });
    },
    edit(type, id) {
      // this.selectproject = ""
      // 任务label.chatter.task
      // 服务预约this.$i18n.t("label.projectManagement.service.appointment")
      // 里程碑this.$i18n.t("component.milestones.label.title")
      // 项目this.$i18n.t("label.file.item")
      this.id = id;
      if (type == "serviceappointment") {
        this.objectApi = "ServiceAppointment";
        this.prefix = "s41";
        this.tabName = this.$i18n.t(
          "label.projectManagement.service.appointment"
        );
      } else if (type == "cloudccMilestone") {
        this.objectApi = type;
        this.prefix = "p02";
        this.tabName = this.$i18n.t("component.milestones.label.title");
      } else if (type == "cloudccTask") {
        this.objectApi = type;
        this.prefix = "p03";
        this.tabName = this.$i18n.t("label.chatter.task");
      } else if (type == "CloudccProject") {
        this.objectApi = type;
        this.prefix = "p01";
        this.tabName = this.$i18n.t("label.file.item");
      } else {
        this.objectApi = type;
        this.prefix = "p05";
        this.tabName = this.$i18n.t("label.projectManagement.subtasks");
      }

      this.$nextTick(() => {
        this.$refs.createEditObj.edit();
      });
    },
    save() {
      this.getlist();
    },
    toUpdate() {
      if (this.radio == 3) {
        this.$refs.changeImplement&&this.$refs.changeImplement.getKanBanView(
          "lichengbei",
          sessionStorage.getItem("selectproject")
        );
      }
    },
    beforeClose() {
      this.showSearchTable = false;
    },
    hidePopver() {
      this.downItemlist = [{ name: "", id: "" }];
      this.currentSelectChecked = "ffe20201207task11";
    },
    searchDownList(val) {
      if (val) {
        this.downLoading = true;
        this.getDownList({ name: val }, "getProjectInfo");
      } else {
        // 最近查看的前五条数据
        this.getTask();
      }
    },
    // 查询下拉项目数据
    getDownList(params, apiType) {
      projectApi[apiType](params)
        .then((res) => {
          if (res.data.length > 0) {
            this.downItemlist = res.data.map((item) => {
              return item;
            });
          } else {
            this.downItemlist = [{ name: "", id: "" }];
            // 获取最近查看的5条信息
            this.getTask();
          }
          this.downLoading = false;
        })
        .catch(() => {
          this.downItemlist = [];
          this.downLoading = false;
        });
    },
    handleItem(id, name) {
      this.ganttLoading = true;
      if (this.isShowkanbanTaskPage == true) {
        let val = "kanbanTaskPage";
        this.$refs.changeImplement&&this.$refs.changeImplement.getKanBanView(val, id);
      }
      this.visible = false;
      this.selectname = name;
      this.selectproject = id;
      sessionStorage.setItem("selectproject", id);
      sessionStorage.setItem("selectname", name);
      // let params = {
      //   parentId: id,
      // };
      // projectApi.getPIdMilestone(params).then((res) => {
      //    for (let i in res.data.tasks.data) {
      //       // if(res.data.tasks.data[i].progress>0){
      //       res.data.tasks.data[i].Nextid =
      //         res.data.tasks.data[i].progress * 100;
      //       res.data.tasks.data[i].Nextname = res.data.tasks.data[
      //         i
      //       ].end_date.substring(0, 10);
      //       res.data.tasks.data[i].end_date = this.getNextDate(
      //         res.data.tasks.data[i].end_date,
      //         1
      //       );
      //       // }
      //       // if (res.data.tasks.data[i].number) {
      //       //   res.data.tasks.data[i].text =
      //       //     res.data.tasks.data[i].number +
      //       //     "." +
      //       //     " " +
      //       //     res.data.tasks.data[i].text;
      //       // }
      //     }
      //     this.tasks.data = res.data.tasks.data;
      //     this.tasks.links = res.data.tasks.links;
      //     // this.$refs.child.reload();
      // this.downItemlist = [{ name: "", id: "" }];
      // 获取最近查看的5条信息
      this.getTask();
      this.getlist();
      // });
    },
    //获取项目接口
    getProjectInfo() {
      // this.loading = this.$loading({
      //   lock: true,
      //   text: "Loading",
      //   spinner: "el-icon-loading",
      //   background: "rgba(255, 255, 255, 0.7)",
      // });
      // 当session中存储的有上次离开的任务id和name时，直接显示存储的任务内容
      if (sessionStorage.getItem("selectproject") || this.$route.query.proId) {
        // 改变展示的项目id和名称
        if (this.$route.query.proId && this.$route.query.proName) {
          // 从项目详情页进甘特图
          this.selectproject = this.$route.query.proId;
          this.selectname = this.$route.query.proName;
          this.getTask();
          this.getlist();
        } else {
          // 默认展示上次离开时的任务信息,需要调接口查下上次查看的任务是否被删除
          this.selectname = sessionStorage.getItem("selectname");
          this.selectproject = sessionStorage.getItem("selectproject");
          this.getlist();
        }
      } else {
        // session中没有上次离开的项目记录，而且不是从项目详情页进来的
        projectApi.getProjectInfo({ name: "" }).then((res) => {
          this.Ganttproject = res.data;
          this.selectname = res?.data[0]?.name;
          this.selectproject = res?.data[0]?.id;
          sessionStorage.setItem("selectproject", this.selectproject || "");
          sessionStorage.setItem("selectname", this.selectname);
          this.getTask();
        });
      }
    },
    //获取页面数据
    getlist() {
      if (this.selectproject) {
        this.$nextTick(() => {
          this.$refs["ganttChild"].ganttoading = true;
          this.$refs["ganttChild"].getPIdMilestoneData();
        });
      }
    },
    //日期转换前一天或者后一天
    getNextDate(date, day) {
      var dd = new Date(date);
      dd.setDate(dd.getDate() + day);
      var y = dd.getFullYear();
      var m =
        dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
      var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
      return y + "-" + m + "-" + d;
    },
    addtask() {
      this.addtaskstate = true;
    },
    cancel() {
      this.pname = "";
      this.tname = "";
      this.display = "0";
      this.dialogVisible = false;
      this.addtaskstate = false;
      this.milepostid = "";
      this.Subtasksid = "";
      // this.$refs.child.reload();
      // this.$refs.child.backScroll() // 获取滚动条位置，并滚动到指定位置
    },
    //选中信息
    selectTask(task, popup) {
      if (popup === "gantt_task_content") {
        this.dialogVisible = true;
      }
      // this.dialogVisible = true;
      this.selectedTask = task;
      this.parentId = task.id;
      let params = {
        parentId: this.parentId,
      };
      gateeApi.getIdSubTask(params).then((res) => {
        if (res.data) {
          this.pname = res.data.pname;
          this.tname = res.data.tname;
        }
      });
    },
    // 前置、后置任务
    displayCheck(flat) {
      this.addtaskstate = false;
      this.display = flat;
      if (flat == "0") {
        //设置前置、后置任务类型
        this.predecessor_task_type = "1";
      } else {
        this.the_rear_task_type = "0";
      }
    },
    //编辑保存
    sure() {
      this.pname = "";
      this.tname = "";
      if (!this.Subtasksid) {
        this.milepostid = "";
        this.Subtasksid = "";
        this.dialogVisible = false;
        this.addtaskstate = false;
        // this.$refs.child.reload();
        return;
      }
      // if(this.addtaskstate){
      let data = [
        {
          id: this.parentId,
        },
      ];
      if (this.display == "0" || this.predecessor_task_type == "") {
        data[0].predecessor_task = this.Subtasksid;
        data[0].predecessor_task_type = "1";
      } else {
        data[0].the_rear_task = this.Subtasksid;
        data[0].the_rear_task_type = this.the_rear_task_type;
      }
      let params = {
        data: JSON.stringify(data),
        objectApi: "cloudccSubtask",
        marketsea: false,
      };
      gateeApi.save(params).then(() => {
        this.getlist();
      });
      this.display = "0";
      this.dialogVisible = false;
      this.addtaskstate = false;
      this.milepostid = "";
      this.Subtasksid = "";
    },

    //获取里程碑信息
    getProjectIdMilestone() {
      let params = {
        parentId: this.$route.query.proId
          ? this.$route.query.proId
          : this.selectproject,
      };
      gateeApi.getProjectIdMilestone(params).then((res) => {
        this.milepost = res.data;
      });
    },
    //根据里程碑id获取此里程碑下所有子任务
    getMilestoneIdSubTask() {
      let params = {
        parentId: this.milepostid,
      };
      gateeApi.getMilestoneIdSubTask(params).then((res) => {
        this.Subtasks = res.data;
      });
    },
    addMessage(message) {
      this.messages.unshift(message);
      if (this.messages.length > 40) {
        this.messages.pop();
      }
    },
    // 判断子任务开始、结束时间是否超出父任务开始、结束时间
    contrastDate(task) {
      // task:需要判断的子任务
      // 判断是否有父任务
      if (task.parent) {
        this.tasks.data.forEach((item) => {
          if (item.id === task.parent) {
            if (
              new Date(task.start_date).getTime() <
                new Date(item.start_date).getTime() ||
              new Date(task.end_date).getTime() >
                new Date(item.end_date).getTime()
            ) {
              // 判断父任务时间和子任务时间
              if (
                new Date(task.start_date).getTime() <
                new Date(item.start_date).getTime()
              ) {
                // 子任务开始时间在父任务开始时间之前
                item.start_date = task.start_date;
              }
              if (
                new Date(task.end_date).getTime() >
                new Date(item.end_date).getTime()
              ) {
                // 子任务结束时间在父任务结束时间之后
                item.end_date = task.end_date;
              }
              // 存储需要修改的任务参数
              // 服务预约参数需要单独判断
              let params = {};
              if (item.type === "serviceappointment") {
                params = {
                  id: item.id,
                  actualstarttime: item.start_date,
                  actualendtime: this.getNextDate(item.end_date, -1),
                  type: "ServiceAppointment",
                };
              } else {
                params = {
                  id: item.id,
                  start_date: item.start_date,
                  end_date: this.getNextDate(item.end_date, -1),
                  fulfill_percent: Math.round(item.progress * 10) * 10 + "%",
                  type: item.type,
                };
              }

              this.changList.push(params);
              // 判断父任务的父任务
              if (item.type !== "cloudccMilestone") {
                this.contrastDate(item);
              }
            }
          }
        });
      }
    },
    //拖拽任务条页面修改
    logTaskUpdate(id, mode, task) {
      // 根据百分比改变状态
      let status = null;
      if (Math.round(task.progress * 10) * 10 === 0) {
        status = this.$i18n.t("label.nostart"); //"未开始"
      } else if (Math.round(task.progress * 10) * 10 === 100) {
        status = "完成"; //完成
      } else {
        status = "进行中"; //"进行中"
      }

      let data = {
        id,
        start_date: task.start_date,
        end_date: this.getNextDate(task.end_date, -1),
        fulfill_percent: Math.round(task.progress * 10) * 10 + "%",
        status,
        type: task.type,
      };
      // 修改的为任务、子任务时，状态字段名为task_status
      if (task.type == "cloudccTask" || task.type == "cloudccSubtask") {
        data = {
          id,
          start_date: task.start_date,
          end_date: this.getNextDate(task.end_date, -1),
          fulfill_percent: Math.round(task.progress * 10) * 10 + "%",
          task_status: status,
          type: task.type,
        };
      }
      if (task.type == "cloudccTask") {
        data.their_milestone = task.parent;
      } else if (task.type == "cloudccSubtask") {
        data.their_task = task.parent;
      }
      //  判断左侧修改结束时间还是右侧修改结束时间
      this.tasks.data.forEach((item) => {
        if (item.id === id) {
          if (task.Nextname !== item.Nextname) {
            // 左侧
            data.end_date = task.Nextname;
            // 改变结束时间，方便判断父任务时使用
            task.end_date = this.getNextDate(task.Nextname, 1);
          } else {
            // 右边
            data.end_date = this.getNextDate(task.end_date, -1);
          }
        }
      });
      // 服务预约参数需要单独判断
      if (task.type === "serviceappointment") {
        data = {
          id,
          actualstarttime: task.start_date,
          actualendtime: this.getNextDate(task.end_date, -1),
          type: "ServiceAppointment",
        };
      }
      if (task.type === "CloudccProject") {
        // 修改服务项目，此时百分比参数名不同，无需检查父任务
        data = {
          id,
          start_date: task.start_date,
          end_date: data.end_date,
          complete_percentage: Math.round(task.progress * 10) * 10 + "%",
          type: task.type,
          status,
        };
      } else if (task.type !== "cloudccMilestone") {
        // 不是服务项目、服务阶段（里程碑），此时需要查找父任务，判断父任务开始、结束时间
        // this.contrastDate(task); // 不需要前端判断父任务了
      }
      this.changList.push(data);
      let axiosArr = [];
      this.changList.forEach((item) => {
        let type = item.type;
        delete item.type;
        let params = {
          data: JSON.stringify([item]),
          objectApi: type,
          marketsea: false,
        };
        axiosArr.push(gateeApi.save(params));
      });
      Promise.all(axiosArr).then(() => {
        this.getlist();
      });
      //  清空参数数据
      this.changList = [];
    },
    logLinkUpdate(id, mode, link) {
      let message = `Link ${mode}: ${id}`;
      if (link) {
        message += ` ( source: ${link.source}, target: ${link.target} )`;
      }
      this.addMessage(message);
    },
    // 返回上一级
    goBack() {
      this.$router.go(-1);
    },
    // 看板任务下拉框切换请求
    getKanBanData(val) {
      if (val === "ffe20201207task09" || val === "ffe20201207task10") {
        this.isShowMilepostBtn = false;
        this.callSubcomponents(val, this.selectproject, "login");
      } else {
        this.callSubcomponents(
          "kanbanTaskPage",
          this.selectproject,
          "ffe20201207task11"
        );
        this.isShowMilepostBtn = true;
      }
      var a = val; //声明个变量存储下数据
      localStorage.setItem("key", a); //将变量imgs存储到name字段
    },
    // 看板子组件传参请求
    childfunc(val) {
      this.currentSelectChecked = val;
    },
    callSubcomponents(valid, id, parameter) {
      this.$refs.changeImplement&&this.$refs.changeImplement.getKanBanView(valid, id, parameter);
    },
    // 控制资源管理，看板任务，甘特图页面显示隐藏
    pageShowHide() {
      if (this.back) {
        // 从任务详情页路由回退的
        let radio = sessionStorage.getItem("radio");
        // 看板任务
        if (radio == "3") {
          this.projectTitleText = this.$i18n.t(
            "label.projectManagement.kanbantask"
          ); // 看板任务 label.projectManagement.kanbantask
          this.isShowkanbanTaskPage = true;
          this.isShowGantt = false;
          this.isShowResource = false;
          this.radio = 3;
          return;
        } else {
          this.isShowkanbanTaskPage = false;
          this.isShowGantt = false;
        }
        // 甘特图
        if (radio == "1") {
          this.isShowGantt = true;
          this.isShowkanbanTaskPage = false;
          this.isShowResource = false;
          this.projectTitleText = this.$i18n.t("label.projectManagement.gantt"); // 甘特图
          this.radio = 1;
        } else {
          this.isShowGantt = false;
        }
        // 资源使用
        if (radio == "2") {
          this.projectTitleText = this.$i18n.t(
            "label.projectManagement.Resourceuse"
          ); // 资源使用label.projectManagement.Resourceuse
          this.isShowResource = true;
          this.isShowGantt = false;
          this.isShowkanbanTaskPage = false;
          this.radio = 2;
        }
      } else {
        // 普通详情页进来的
        // 看板任务
        if (this.$route.query.isGantt == "kanbanTaskPage") {
          this.projectTitleText = this.$i18n.t(
            "label.projectManagement.kanbantask"
          ); // 看板任务
          this.isShowkanbanTaskPage = true;
          this.isShowGantt = false;
          this.isShowResource = false;
          this.radio = 3;
          sessionStorage.setItem("radio", 3);
          return;
        } else {
          this.isShowkanbanTaskPage = false;
          this.isShowGantt = false;
        }

        // 甘特图
        if (
          this.$route.query.isGantt == "isGantt" ||
          this.$route.path === "/gantt/gantt"
        ) {
          this.isShowGantt = true;
          this.isShowkanbanTaskPage = false;
          this.isShowResource = false;
          this.projectTitleText = this.$i18n.t("label.projectManagement.gantt"); // 甘特图
          this.radio = 1;
          sessionStorage.setItem("radio", 1);
        } else {
          this.isShowGantt = false;
        }
        // 资源使用
        if (this.$route.query.isGantt == "isGantt2") {
          this.projectTitleText = this.$i18n.t(
            "label.projectManagement.Resourceuse"
          ); // 资源使用
          this.isShowResource = true;
          this.isShowGantt = false;
          this.isShowkanbanTaskPage = false;
          this.radio = 2;
          sessionStorage.setItem("radio", 2);
        }
      }
    },
    // 看板任务新建任务按钮
    newMilestoneButton() {
      this.relativeRecordId = this.$route.query.proId
        ? this.$route.query.proId
        : sessionStorage.getItem("selectproject");
      // 
      this.relationFieldId = "ffe2020Milestone01";
      this.objectApi = "cloudccMilestone";
      this.tabName = this.$i18n.t("component.milestones.label.title"); // 里程碑this.$i18n.t("component.milestones.label.title")
      this.prefix = "p02";
      this.$nextTick(() => {
        this.$refs.createEditObj.add();
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    //  确定刷新展示的模块组件
    let flag = from.path === "/";
    // 在渲染该组件的对应路由被 confirm 前调用
    // 不！能！获取组件实例 `this`
    // 因为当守卫执行前，组件实例还没被创建
    next((vm) => {
      if (from.query.sign === "back" || flag) {
        // 路由时回退的
        vm.back = true;
        return;
      } else {
        vm.back = false;
        return;
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    to.query.proId ? (this.isShowGoback = true) : (this.isShowGoback = false);
    // 控制展示的组件
    if (this.radio === 1) {
      this.isShowGantt = true;
      this.isShowkanbanTaskPage = false;
      this.isShowResource = false;
      this.projectTitleText = this.$i18n.t("label.projectManagement.gantt"); // 甘特图
    }
    if (this.radio === 2) {
      this.projectTitleText = this.$i18n.t(
        "label.projectManagement.Resourceuse"
      ); // 资源使用
      this.isShowResource = true;
      this.isShowGantt = false;
      this.isShowkanbanTaskPage = false;
    }
    if (this.radio === 3) {
      this.projectTitleText = this.$i18n.t(
        "label.projectManagement.kanbantask"
      ); // 看板任务
      this.isShowkanbanTaskPage = true;
      this.isShowGantt = false;
      this.isShowResource = false;
    }
    this.getProjectInfo();
    next();
  },
  mounted() {
    this.pageShowHide();
    if (this.$route.query.isGantt == "kanbanTaskPage") {
      this.radio = 3;
    }
    this.projectId = this.$route.query.proId; //获取项目id。
    this.$bus.$on("taskupdate", this.selectTask);
    this.getProjectInfo();
    this.$route.query.proId
      ? (this.isShowGoback = true)
      : (this.isShowGoback = false);
  },
  beforeDestroy() {
    this.$bus.$off("taskupdate", this.selectTask);
  },
};
</script>

<style scoped lang="scss">
.kanbanBox {
  height: calc(100% - 62px);
}
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.con {
  height: 100%;
  width: 100%;
}
.con_Header {
  // height: 50px;
  width: 100%;
  display: flex;
  background: #ffffff;
  align-items: center;
  // padding-top: 8px;
}
.con_Header_left {
  height: 100%;
  width: 40%;
}
.con_Header_right {
  height: 100%;
  width: 60%;
  display: flex;
  position: relative;
  .fixed_btn {
    flex: 1;
    text-align: right;
    padding-top: 13px;
    padding-right: 10px;
    ::v-deep .el-select {
      width: 95px;
      padding-left: 5px;
    }
    ::v-deep .el-input--suffix .el-input__inner {
      border: 0px;
    }
    ::v-deep .el-select .el-input .el-select__caret::before {
      content: "";
      width: 0;
      height: 0;
      border: 6px solid #fff;
      border-top-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;
      position: relative;
      top: -12px;
    }
    ::v-deep .el-select .el-input__inner {
      font-size: 12px;
      color: #fff;
      height: 28px;
      background: #005fb2;
    }
    ::v-deep .el-input__icon {
      line-height: 32px;
    }
  }
}
.left-container {
  overflow: hidden;
  position: relative;
  height: calc(100% - 60px);
}

.right-container {
  border-right: 1px solid #cecece;
  float: right;
  height: 100%;
  width: 340px;
  box-shadow: 0 0 5px 2px #aaa;
  position: relative;
  z-index: 2;
}
.gantt_task_row .gantt_task_cell.weekend {
  background-color: #f3f2f3 !important;
}
.gantt-messages {
  list-style-type: none;
  height: 50%;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding-left: 5px;
}

.gantt-messages > .gantt-message {
  background-color: #f4f4f4;
  box-shadow: inset 5px 0 #d69000;
  /* font-family: Geneva, Arial, Helvetica, sans-serif; */
  font-size: 14px;
  margin: 5px 0;
  padding: 8px 0 8px 10px;
}

.gantt-selected-info {
  border-bottom: 1px solid #cecece;
  box-sizing: border-box;
  /* font-family: Geneva, Arial, Helvetica, sans-serif; */
  height: 50%;
  line-height: 28px;
  padding: 10px;
}

.gantt-selected-info h2 {
  border-bottom: 1px solid #cecece;
}
.weekend {
  background: #f4f7f4;
}

.gantt_selected .weekend {
  background: #f7eb91;
}
.select-task-prompt h2 {
  color: #d9d9d9;
}

.el-dialog__header {
  background: #62a5f3;
  margin-bottom: 0px;
}

/* .header-titletext {
  display: flex;
  justify-content: space-between;
}
.header-titletext span {
  font-size: 14px;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #ffffff;
  line-height: 21px;
} */

.dialog-center {
  /* border-bottom: 2px solid #d8d8d8; */
  display: flex;
  justify-content: space-between;
}

.dialog-center p {
  font-size: 14px;
  /* font-family: SourceHanSansCN-Regular, SourceHanSansCN; */
  font-weight: 400;
  color: #080707;
  line-height: 21px;
}
.el-dialog__body {
  padding: 0px;
  margin-top: -30px;
}
.dialog-centerleft {
  /* margin-left: 36px; */
  /* margin-top: 44px; */
}

.dialog-centerright {
  /* margin-top: 44px; */
  margin-right: 17px;
}
.gantt_demo {
  /* border: 2px solid red; */
  /* color: red; */
  background: red;
}

.gantt_task_line {
  background-color: #62a5f3;
}
.add-task {
  /* border-bottom: 2px solid #d8d8d8; */
  /* margin-left: 36px; */
}

.add-task p {
  font-size: 14px;
  /* font-family: SourceHanSansCN-Regular, SourceHanSansCN; */
  font-weight: 400;
  color: #006dcc;
  line-height: 21px;
}
.goto-task h3 {
  font-size: 14px;
  /* font-family: SourceHanSansCN-Regular, SourceHanSansCN; */
  font-weight: 400;
  color: #080707;
  line-height: 21px;
}

.gantt_task_line {
  height: 18px !important;
  line-height: 18px !important;
  margin-top: 9px;
  border-radius: 9px;
  border: none !important;
}

.goto-taskinput {
  width: 398px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #e3e2e2;
  margin-top: 5px;
  margin-bottom: 5px;
}

.goto-taskinput img {
  margin-right: 8px;
}

.goto-taskinput input {
  border: none;
  border: 0;
  outline: none;
}

.top-tltle {
  display: flex;
}

.top-tltle .logo-img {
  width: 37px;
  height: 37px;
  margin: 10px 8px 19px 10px;
}

.all-task .all-tasks {
  margin-right: 6px;
  font-size: 18px;
  color: #080707;
  font-weight: 600;
  overflow: hidden;
  max-width: 600px;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.choose-view {
  width: 100%;
  margin: 10px 0 0 10px;
  /* height: 200px; */
  /* background: pink; */
}
.left {
  width: 7px;
  height: 12px;
  margin-left: 11px;
  margin-top: 17px;
  margin-right: 8px;
  float: left;
  cursor: pointer;
}

.paperview-input-text >>> .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 0px;
  width: 100%;
}

.downIcon {
  display: flex;
  cursor: pointer;
  align-items: center;
}
// svg图标样式
.operationImg {
  width: 37px;
  height: 37px;
  margin: 10px;
}
</style>
