// 判断所有人id为005开头的，则可以跳转至详情页
export function owneridIsJump(owneridValue) {
  if (owneridValue && owneridValue.slice(0, 3) === '005') {
    return true; // 可跳转
  } else {
    return false; // 不可跳转
  }
}


