<template>
  <div class="vue-dropdown default-theme">
    <div class="search-module clearfix">
      <!-- @keyup.enter.native="searchList" -->
      <el-input
        class="search-text"
        v-model="value"
        :placeholder="placeholder"
        @input="searchList"
      ></el-input>
      <span class="el-icon-search search-icon" @click="searchList"></span>
    </div>
    <ul class="list-module" v-show="show && !loading">
      <li
        v-for="(item, index) in itemlistChild"
        @click="itemClick(item.id, item.name)"
        :key="index"
        :class="item.id === selectproject ? 'active' : ''"
      >
        <span class="list-item-text">{{ item.name }}</span>
      </li>
    </ul>
    <div class="tip_load el-icon-loading" v-show="loading"></div>
    <div class="tip_nodata" v-show="!show && !loading">{{ nodatatext }}</div>
  </div>
</template>

<script>
import debounce from "lodash.debounce";
export default {
  data() {
    return {
      itemlistChild: [],
      show: true,
      value: "",
    };
  },
  props: {
    aa: {
      type: Boolean,
    },
    itemlist: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default() {
        return this.$i18n.t("label.projectManagement.Pleaseenterprojectname");
      },
      //请输入项目名称...
    },
    nodatatext: {
      type: String,
      default() {
        return this.$i18n.t("label.projectManagement.No.matching.data");
      },
      //暂无匹配数据
    },
    loading: {
      type: Boolean,
      default: false,
    },
    // 选中项目的id
    selectproject: {
      type: String,
    },
  },
  watch: {
    itemlist: {
      immediate: true,
      handler(val) {
        this.itemlistChild = val;
        this.show = val.length;
      },
    },
  },
  methods: {
    itemClick(id, name) {
      this.value = name;
      this.$emit("handleItem", id, name);
    },
    searchList: debounce(function () {
      this.$emit("searchDownList", this.value);
    }, 300),
  },
  created() {
    this.itemlistChild = this.itemlist;
  },
  mounted() {
    this.itemlistChild = this.itemlist;
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input.is-active .el-input__inner,
.el-input__inner:focus {
  border-color: #ffffff;
  outline: 0;
}
::v-deep .el-input__inner:hover {
  border-color: #ffffff;
}
::v-deep .el-input__inner {
  height: 20px;
  padding: 0;
  border: 0;
  width: 85%;
}
.vue-dropdown.default-theme {
  overflow: hidden;
  margin: 5px -12px;
  .search-module {
    position: relative;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 10px;
    .search-text {
      width: 100%;
      height: 30px;
      padding: 5px 20px;
      box-shadow: none;
      border: none;
      outline: none;
      box-sizing: border-box;
      &:focus {
        border: none;
        outline: none;
        box-sizing: border-box;
      }
    }

    .search-icon {
      cursor: pointer;
      position: absolute;
      top: 24%;
      right: 20px;
      color: #aaa;
    }
  }

  .list-module {
    max-height: 200px;
    overflow-y: auto;
    padding: 0 20px;
    color: #333;
    li {
      &.active {
        color: #00a0e9;
      }
      &._self-hide {
        display: none;
      }
      padding: 5px 0;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.tip_nodata {
  font-size: 12px;
  padding-left: 20px;
}
.tip_load {
  width: 100%;
  font-size: 16px;
  text-align: center;
  padding: 20px 0;
}
</style>
